import axios from "axios";
import useConfig from "./useConfig";

function useMeteringPoints() {
  const { token, meteringPointsAPI } = useConfig();
  const headers = { Authorization: "Bearer " + token };

  async function getPoints(limit = 9999999999) {
    const result = await axios.get(meteringPointsAPI + "/", {
      headers,
      params: { limit },
    });
    return result.data;
  }

  return { getPoints };
}

export default useMeteringPoints;
