import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { withRouter } from "react-router-dom";

import {
  Drawer,
  Typography,
  Toolbar,
  AppBar,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
} from "@material-ui/core";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import WarningIcon from "@material-ui/icons/Warning";
import AdjustIcon from "@material-ui/icons/Adjust";
import SettingsIcon from "@material-ui/icons/Settings";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import ImportExportIcon from "@material-ui/icons/ImportExport";

import AccountMenu from "./AccountMenu";
import CalculatorIcon from "./CalculatorIcon";
import styles from "../styles";

class AppBarDrawer extends React.Component {
  state = {};

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if (event.altKey && event.key === "1") {
      this.handleAppMenuChoose("/alarms");
    } else if (event.altKey && event.key === "2") {
      this.handleAppMenuChoose("/meteringpoints");
    }
  };

  handleAppMenuOpen = () => {
    this.setState({ appMenuOpen: true });
  };

  handleAppMenuClose = () => {
    this.setState({ appMenuOpen: false });
  };

  handleAppMenuChoose = (view) => {
    this.handleAppMenuClose();
    this.props.history.push(view);
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <AppBar
          position="absolute"
          className={classNames(classes.AppBar, this.state.appMenuOpen && classes.AppBarShift)}
        >
          <Toolbar disableGutters={!this.state.open}>
            <IconButton
              color="inherit"
              onClick={this.handleAppMenuOpen}
              disabled={this.state.appMenuOpen}
              className={classes.AppMenuButton}
            >
              <MenuIcon />
            </IconButton>
            <img src={"/logo.png"} alt={"K2 Logo"} height={40} className={classes.MarginLeft} />
            <Typography variant="h6" color="inherit" className={classNames(classes.MarginLeft, classes.FlexGrow)} />
            <Typography color="inherit">
              {this.props.context.keycloak.tokenParsed.name + " (" + this.props.context.keycloak.role + ")"}
            </Typography>
            <AccountMenu
              language={this.state.language}
              languages={this.props.languages}
              updateLanguage={this.props.updateLanguage}
            />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.DrawerPaper, !this.state.appMenuOpen && classes.DrawerPaperClose),
          }}
          open={this.state.appMenuOpen}
        >
          <div className={classes.AppToolbar}>
            <IconButton onClick={this.handleAppMenuClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>

          <ListItem button onClick={() => this.handleAppMenuChoose("/status")} className={classes.DrawerIcon}>
            <ListItemIcon>
              <ImportExportIcon />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="alarms" />
            </ListItemText>
          </ListItem>
          <ListItem button onClick={() => this.handleAppMenuChoose("/alarms")} className={classes.DrawerIcon}>
            <ListItemIcon>
              <WarningIcon />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="alarms" />
            </ListItemText>
          </ListItem>
          <ListItem button onClick={() => this.handleAppMenuChoose("/meteringpoint")} className={classes.DrawerIcon}>
            <ListItemIcon>
              <AdjustIcon />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="metering_point" />
            </ListItemText>
          </ListItem>
          <ListItem button onClick={() => this.handleAppMenuChoose("/diffcalculation")} className={classes.DrawerIcon}>
            <ListItemIcon>
              <CalculatorIcon />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="diffcalculation" />
            </ListItemText>
          </ListItem>
          <ListItem button onClick={() => this.handleAppMenuChoose("/meteringpoints")} className={classes.DrawerIcon}>
            <ListItemIcon>
              <HomeWorkIcon />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="metering_points" />
            </ListItemText>
          </ListItem>
          <ListItem button onClick={() => this.handleAppMenuChoose("/cooling")} className={classes.DrawerIcon}>
            <ListItemIcon>
              <AcUnitIcon />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="cooling" />
            </ListItemText>
          </ListItem>
          <ListItem button onClick={() => this.handleAppMenuChoose("/configuration")} className={classes.DrawerIcon}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="configuration" />
            </ListItemText>
          </ListItem>
        </Drawer>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(AppBarDrawer));
