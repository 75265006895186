import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useIntl } from "react-intl";
import MomentUtils from "@date-io/moment";
import { makeStyles, Button, Grid, TextField, CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import AppContext from "../AppContext";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import styles from "../styles";
//Lines below have to be last
import createPlotlyComponent from "react-plotly.js/factory";
import Plotly from "plotly.js-basic-dist-min";
const Plot = createPlotlyComponent(Plotly);
const useStyles = makeStyles(styles);

//Comment added by Kristoffer to test if new deployment works.

export default function RefPeriodPreview(props) {
  const context = useContext(AppContext);
  const classes = useStyles();
  const intl = useIntl();

  const [open, setOpen] = useState(false);
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [meteringPointId, setMeteringPointId] = useState("");

  const [energyErrors, setEnergyErrors] = useState(0.0);
  const [energyDiff, setEnergyDiff] = useState(0.0);
  const [energyAbsDiff, setEnergyAbsDiff] = useState(0.0);
  const [flowErrors, setFlowErrors] = useState(0.0);
  const [flowDiff, setFlowDiff] = useState(0.0);
  const [flowAbsDiff, setFlowAbsDiff] = useState(0.0);
  const [totDaysEnergy, setTotDaysEnergy] = useState(0.0);
  const [totDaysFlow, setTotDaysFlow] = useState(0.0);
  const [totDays, setTotDays] = useState(0.0);
  const [energyDiagramData, setEnergyDiagramData] = useState(getData());

  const [delayed, setDelayed] = useState(false);

  const handleClose = () => {
    props.closeHandle(false);
  };

  const handleSaveAndClose = () => {
    saveRefPeriod();
  };

  useEffect(() => {
    setOpen(props.open);
    setFrom(new Date(props.refFrom));
    setTo(new Date(props.refTo));
    setMeteringPointId(props.meteringPointId);
  }, [props]);

  const handleRefFromDateInput = (event) => {
    if (event && event.isValid()) {
      setFrom(new Date(Date.UTC(event._d.getFullYear(), event._d.getMonth(), event._d.getDate())));
    }
  };

  const handleRefToDateInput = (event) => {
    if (event && event.isValid()) {
      setTo(new Date(Date.UTC(event._d.getFullYear(), event._d.getMonth(), event._d.getDate())));
    }
  };
  function createTimeLine() {
    let timeline = {};
    for (let dt = new Date(from); dt <= to; dt.setUTCDate(dt.getUTCDate() + 1)) {
      timeline[new Date(dt)] = "missing";
    }
    return timeline;
  }

  function showMeterReadings(meterReadings) {
    let timeline = createTimeLine();
    setTotDays(Object.keys(timeline).length);
    let totDaysEnergi = 0.0;
    let totDaysFlow = 0.0;
    let energyGroup = {
      type: "scatter",
      mode: "lines+markers",
      name: intl.formatMessage({ id: "metering_point.ref_preview.diagram.energy_legend" }),
      connectgaps: false,
      line: {
        width: 1,
        color: "blue",
      },
    };
    let energyX = [];
    let energyY = [];

    let missingGroup = {
      type: "scatter",
      mode: "markers",
      name: intl.formatMessage({ id: "metering_point.ref_preview.diagram.missing_legend" }),
      connectgaps: false,
      line: {
        width: 1,
        color: "red",
      },
    };
    let missingX = [];
    let missingY = [];

    meterReadings.forEach((meterReading) => {
      if (meterReading.property === "energy") {
        totDaysEnergi += 1;
        energyX.push(new Date(meterReading.consumption.from));
        energyY.push(roundValue(meterReading.consumption.value, 3));
        timeline[new Date(meterReading.consumption.from)] = "found";
      }
      if (meterReading.property === "flow") {
        totDaysFlow += 1;
      }
    });
    Object.keys(timeline).forEach((key) => {
      if (timeline[key] === "missing") {
        missingY.push(0.0);
        missingX.push(new Date(key));
      }
    });
    missingGroup["x"] = missingX;
    missingGroup["y"] = missingY;
    energyGroup["x"] = energyX;
    energyGroup["y"] = energyY;
    let data = [];
    data.push(energyGroup);
    data.push(missingGroup);
    setTotDaysEnergy(totDaysEnergi);
    setTotDaysFlow(totDaysFlow);
    setEnergyDiagramData(data);
  }

  function fetchMeterReadings(token) {
    var headers = { Authorization: "Bearer " + token };
    let params = {
      metering_point_id: meteringPointId,
      include_from: from.toISOString(),
      include_to: to.toISOString(),
      limit: 999999999,
      offset: 0,
      validated: false,
      fields: "consumption",
      property: "energy,flow",
    };
    return axios.get(context.config.meterReadingsAPI + "/validated", { headers, params });
  }

  // function fetchNewCoff(token) {
  //   var headers = { Authorization: "Bearer " + token };
  //   let params = {
  //     metering_point_id: meteringPointId,
  //     include_from: from,
  //     include_to: to,
  //   };
  //   return axios.get(context.config.regressionAPI + "/coefficients", { headers, params });
  // }

  function setRefPeriod(token) {
    var headers = { Authorization: "Bearer " + token };
    let data = {
      from: new Date(from).toISOString().split("T")[0],
      to: new Date(to).toISOString().split("T")[0],
    };
    return axios.put(context.config.meteringPointsAPI + "/" + meteringPointId + "/reference_period", data, { headers });
  }

  function fetchRegression(token, ref_from, ref_to, body) {
    let headers = { Authorization: "Bearer " + token };
    let end = new Date();
    let start = new Date();
    start.setDate(end.getDate() - 365);
    let params = {
      metering_point_id: meteringPointId,
      include_from: start.toISOString(),
      include_to: end.toISOString(),
      reference_from: ref_from,
      reference_to: ref_to
    };
    return axios.put(context.config.regressionAPI + "/regression", body, { headers, params });
    // return axios.put(context.config.regressionAPI + "/regression", body, { headers, params });
  }

  function getStdAv(coffGroup, temperature, property) {
    let coff = {};
    coffGroup.forEach((coffItem) => {
      if (coffItem.type_of_coefficient === property && coffItem.group === 0) {
        coff = coffItem;
      }
    });
    if (temperature < -5.0) {
      if (coff.values.sd1 !== -99999) {
        return coff.values.sd1;
      }
      if (coff.values.sd2 !== -99999) {
        return coff.values.sd2;
      }
      if (coff.values.sd3 !== -99999) {
        return coff.values.sd3;
      }
      if (coff.values.sd4 !== -99999) {
        return coff.values.sd4;
      }
      if (coff.values.sd5 !== -99999) {
        return coff.values.sd5;
      }
    } else if (temperature < 2.0) {
      if (coff.values.sd2 !== -99999) {
        return coff.values.sd2;
      }
      if (coff.values.sd3 !== -99999) {
        return coff.values.sd3;
      }
      if (coff.values.sd4 !== -99999) {
        return coff.values.sd4;
      }
      if (coff.values.sd5 !== -99999) {
        return coff.values.sd5;
      }
      if (coff.values.sd1 !== -99999) {
        return coff.values.sd1;
      }
    } else if (temperature < 9.0) {
      if (coff.values.sd3 !== -99999) {
        return coff.values.sd3;
      }
      if (coff.values.sd4 !== -99999) {
        return coff.values.sd4;
      }
      if (coff.values.sd5 !== -99999) {
        return coff.values.sd5;
      }
      if (coff.values.sd2 !== -99999) {
        return coff.values.sd2;
      }
      if (coff.values.sd1 !== -99999) {
        return coff.values.sd1;
      }
    } else if (temperature < 15.0) {
      if (coff.values.sd4 !== -99999) {
        return coff.values.sd4;
      }
      if (coff.values.sd5 !== -99999) {
        return coff.values.sd5;
      }
      if (coff.values.sd3 !== -99999) {
        return coff.values.sd3;
      }
      if (coff.values.sd2 !== -99999) {
        return coff.values.sd2;
      }
      if (coff.values.sd1 !== -99999) {
        return coff.values.sd1;
      }
    } else if (temperature < 20.0) {
      if (coff.values.sd5 !== -99999) {
        return coff.values.sd5;
      }
      if (coff.values.sd4 !== -99999) {
        return coff.values.sd4;
      }
      if (coff.values.sd3 !== -99999) {
        return coff.values.sd3;
      }
      if (coff.values.sd2 !== -99999) {
        return coff.values.sd2;
      }
      if (coff.values.sd1 !== -99999) {
        return coff.values.sd1;
      }
    } else {
      return 0;
    }
  }

  function calculateErrors(meteringPointData, coffData) {
    let energyErrors = 0;
    let flowErrors = 0;
    let totalEnergyAbsDiff = 0.0;
    let totalFlowAbsDiff = 0.0;
    let totalEnergyDiff = 0.0;
    let totalFlowDiff = 0.0;

    meteringPointData.forEach((meteringPoint) => {
      if (meteringPoint.property === "energy") {
        let diff = meteringPoint.consumption - meteringPoint.calculated_reference;
        let absDiff = Math.abs(diff);
        totalEnergyAbsDiff += absDiff;
        totalEnergyDiff += diff;
        let stdAv = getStdAv(coffData, meteringPoint.temperature, "energy");
        if (absDiff / stdAv > 2.2) {
          energyErrors += 1;
        }
      }
      if (meteringPoint.property === "flow") {
        let diff = meteringPoint.consumption - meteringPoint.calculated_reference;
        let absDiff = Math.abs(diff);
        totalFlowAbsDiff += absDiff;
        totalFlowDiff += diff;
        let stdAv = getStdAv(coffData, meteringPoint.temperature, "flow");
        if (absDiff / stdAv > 2.2) {
          flowErrors += 1;
        }
      }
    });
    setEnergyErrors(energyErrors);
    setEnergyDiff(totalEnergyDiff);
    setEnergyAbsDiff(totalEnergyAbsDiff);
    setFlowErrors(flowErrors);
    setFlowDiff(totalFlowDiff);
    setFlowAbsDiff(totalFlowAbsDiff);
  }

  async function fetchCoff(ref_from, ref_to) {
    setDelayed(true);
    context.keycloak
      .updateToken(5)
      .success(function () {
        axios
          .all([
            // fetchNewCoff(context.keycloak.token),
            fetchMeterReadings(context.keycloak.token)
          ])
          .then(
            // axios.spread(function (coffResponse, meterReadingsResponse) {
            axios.spread(function (meterReadingsResponse) {
              // fetchRegressionAndCalc(coffResponse.data);

              var body = [  //TODO Remove once coefficients are gone from regression/regression
                  {
                      "from": new Date(from).toISOString().substr(0,10),
                      "to": new Date(to).toISOString().substr(0,10),
                      "group": 1,
                      "type_of_coefficient": "energy",
                      "period": 0,
                      "values": {
                          "c1": 1,
                          "c2": 1,
                          "c3": 1,
                          "c4": 1,
                          "c5": 1,
                          "sd1": 1,
                          "sd2": 1,
                          "sd3": 1,
                          "sd4": 1,
                          "sd5": 1
                      }
                  }
              ]

              fetchRegressionAndCalc(body, from, to);
              showMeterReadings(meterReadingsResponse.data);
            })
          )
          .catch((error) => {
            console.log(error);
            setDelayed(false);
          });
      })
      .error(function () {
        context.keycloak.login();
        setDelayed(false);
      });
  }
  async function fetchRegressionAndCalc(coefficients, ref_from, ref_to) {
    context.keycloak
      .updateToken(5)
      .success(function () {
        axios
          .all([fetchRegression(context.keycloak.token, ref_from, ref_to, coefficients)])
          .then(
            axios.spread(function (regressionResponse) {
              setDelayed(true);
              calculateErrors(regressionResponse.data, coefficients);
              setDelayed(false);
            })
          )
          .catch((error) => {
            console.log(error);
            setDelayed(false);
          });
      })
      .error(function () {
        context.keycloak.login();
        setDelayed(false);
      });
  }
  async function saveRefPeriod() {
    context.keycloak
      .updateToken(5)
      .success(function () {
        axios
          .all([setRefPeriod(context.keycloak.token)])
          .then(
            axios.spread(function (refPeriodResponse) {
              props.closeHandle();
            })
          )
          .catch((error) => {
            console.log(error);
          });
      })
      .error(function () {
        context.keycloak.login();
      });
  }

  const handlePreview = (event) => {
    setDelayed(true);
    // fetchRegressionAndCalc(
    //   body,
    //   new Date(from).toISOString().substr(0,10),
    //   new Date(to).toISOString().substr(0,10)
    // )
    fetchCoff();
  };

  const meteringPointRefPeriodStart = (
    <KeyboardDatePicker
      className={classes.DatePicker}
      allowKeyboardControl
      autoOk
      disableFuture
      invalidDateMessage={intl.formatMessage({
        id: "invalid_date_message",
      })}
      minDateMessage={intl.formatMessage({
        id: "min_date_message",
      })}
      maxDateMessage={intl.formatMessage({
        id: "max_date_message",
      })}
      label={intl.formatMessage({
        id: "metering_points.ref_period_from",
      })}
      InputLabelProps={{ shrink: true }}
      format="YYYY-MM-DD"
      onChange={handleRefFromDateInput}
      value={from}
    />
  );

  const meteringPointRefPeriodEnd = (
    <KeyboardDatePicker
      className={classes.DatePicker + " " + classes.MarginRight}
      allowKeyboardControl
      autoOk
      disableFuture
      invalidDateMessage={intl.formatMessage({
        id: "invalid_date_message",
      })}
      minDateMessage={intl.formatMessage({
        id: "min_date_message",
      })}
      maxDateMessage={intl.formatMessage({
        id: "max_date_message",
      })}
      label={intl.formatMessage({
        id: "metering_points.ref_period_to",
      })}
      InputLabelProps={{ shrink: true }}
      format="YYYY-MM-DD"
      onChange={handleRefToDateInput}
      value={to}
    />
  );

  function roundValue(value, number_of_decimals) {
    try {
      return +value.toFixed(number_of_decimals);
    } catch (error) {
      return null;
    }
  }

  function getLayout() {
    return {
      title: {
        text: intl.formatMessage({
          id: "metering_point.ref_preview.diagram.title"
        }),
        y: 1,
        x: 0.5,
        xanchor: "center",
        yanchor: "top",
        pad: {t: 8}
      },
      margin: { l: 50, r: 50, t: 10, b: 10, pad: 10 },
      autosize: true,
      legend: { orientation: "h" },
      yaxis: {
        title: intl.formatMessage({ id: "chart.energy_per_day" }),
        titlefont: { size: 9 }
      },
      plot_bgcolor: "white",
    };
  }
  function getData() {
    return [
      {
        x: [1, 2, 3],
        y: [2, 2, 2],
        type: "scatter",
        mode: "lines+points",
        marker: { color: "red" },
      },
    ];
  }

  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose}>
        <MuiDialogTitle>{intl.formatMessage({ id: "metering_point.ref_preview.title" })}</MuiDialogTitle>
        <MuiDialogContent>
          <Grid container spacing={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid item xs={3}>
                {meteringPointRefPeriodStart}
              </Grid>
              <Grid item xs={3}>
                {meteringPointRefPeriodEnd}
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid item xs={6}>
              <Button variant="contained" color="primary" onClick={handlePreview}>
                {intl.formatMessage({ id: "metering_point.ref_preview.preview" })}
              </Button>
            </Grid>
            {delayed === false ? (
              <React.Fragment>
                <Grid item xs={3}>
                  <TextField
                    id="energyErrors"
                    label={intl.formatMessage({ id: "metering_point.ref_preview.number_of_errors.energy" })}
                    variant="outlined"
                    value={energyErrors}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="energyDiff"
                    label={intl.formatMessage({ id: "metering_point.ref_preview.difference.energy" })}
                    variant="outlined"
                    value={roundValue(energyDiff, 3)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="energyAbsDiff"
                    label={intl.formatMessage({ id: "metering_point.ref_preview.abs_difference.energy" })}
                    variant="outlined"
                    value={roundValue(energyAbsDiff, 3)}
                  />
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                  <TextField
                    id="flowErrors"
                    label={intl.formatMessage({ id: "metering_point.ref_preview.number_of_errors.flow" })}
                    variant="outlined"
                    value={flowErrors}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="flowDiff"
                    label={intl.formatMessage({ id: "metering_point.ref_preview.difference.flow" })}
                    variant="outlined"
                    value={roundValue(flowDiff, 3)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="flowAbsDiff"
                    label={intl.formatMessage({ id: "metering_point.ref_preview.abs_difference.flow" })}
                    variant="outlined"
                    value={roundValue(flowAbsDiff, 3)}
                  />
                </Grid>
                <Grid item xs={3}>
                  {" "}
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="totDaysEnergy"
                    label={intl.formatMessage({ id: "metering_point.ref_preview.number_of_days.energy" })}
                    variant="outlined"
                    value={totDaysEnergy}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="totDaysFlow"
                    label={intl.formatMessage({ id: "metering_point.ref_preview.number_of_days.flow" })}
                    variant="outlined"
                    value={totDaysFlow}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="totDays"
                    label={intl.formatMessage({ id: "metering_point.ref_preview.tot_days" })}
                    variant="outlined"
                    value={totDays}
                  />
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={12}>
                  <Plot data={energyDiagramData} layout={getLayout()} config={{ responsive: true }} />
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid item xs={5}></Grid>
                <Grid item xs={1}>
                  <CircularProgress />
                </Grid>
                <Grid item xs={5}></Grid>
              </React.Fragment>
            )}
          </Grid>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button variant="contained" onClick={handleClose} color="secondary">
            {intl.formatMessage({ id: "metering_point.ref_preview.close" })}
          </Button>
          <Button variant="contained" onClick={handleSaveAndClose} color="primary">
            {intl.formatMessage({ id: "metering_point.ref_preview.save" })}
          </Button>
        </MuiDialogActions>
      </Dialog>
    </React.Fragment>
  );
}
