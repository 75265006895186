import React, { useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { withStyles, Typography, Grid, Paper, Toolbar, Button, ButtonGroup, CircularProgress } from "@material-ui/core";
import useStatus from "../services/useStatus";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { useIntl } from "react-intl";
import styles from "../styles";
import StatusDaysButtonGroup from "./StatusDaysButtonGroup";
import createPlotlyComponent from "react-plotly.js/factory";
import Plotly from "plotly.js-basic-dist-min";
const Plot = createPlotlyComponent(Plotly);

const errorColumns = [
  {
    name: "_id",
    label: <FormattedMessage id="status.metering_point_id" />,
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "address.street_address",
    label: <FormattedMessage id="status.address" />,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "error",
    label: <FormattedMessage id="status.error_code" />,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "desc",
    label: <FormattedMessage id="status.description" />,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "timestamp",
    label: <FormattedMessage id="status.timestamp" />,
    options: {
      filter: true,
      sort: true,
    },
  },
];

const missingColumns = [
  {
    name: "metering_point_id",
    label: <FormattedMessage id="status.metering_point_id" />,
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "address.street_address",
    label: <FormattedMessage id="status.address" />,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "day",
    label: <FormattedMessage id="status.day" />,
    options: {
      filter: true,
      sort: true,
    },
  },
];

const tableOptions = {
  filterType: "multiselect",
  selectableRows: "none",
  responsive: "scrollMaxHeigh",
  elevation: 0,
  rowsPerPageOptions: [10, 20, 50],
  viewColumns: true,
  toolbar: true,
};

const Status = ({ classes }) => {
  const intl = useIntl();
  // const yesterday = "2023-11-22";
  const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");

  const { dayStatus, prevDays, setnDays, isLoading, nDays, formatStatusLabels } = useStatus(yesterday);

  return (
    <React.Fragment>
      <StatusDaysButtonGroup isLoading={isLoading} setnDays={setnDays} nDays={nDays} />
      <Grid container>
        <Grid item xs={12} className={classes.StatusHeader}>
          <Typography variant="h5" color="textSecondary">
            <FormattedMessage id="status.reading_status" /> {yesterday}
          </Typography>
          <p>
            {dayStatus[yesterday] ? dayStatus[yesterday].nMeasures : 0} <FormattedMessage id="status.loaded_readings" />
          </p>
        </Grid>
        <Grid item xs={4} className={classes.ChartGrids}>
          <h5 onClick={(e) => setnDays(10)} className={classes.ChartHeaders}>
            <FormattedMessage id="status.distribution" />
          </h5>
          {dayStatus[yesterday] && (
            <Plot
              data={[
                {
                  values: [dayStatus[yesterday].nErrors, ...Object.values(dayStatus[yesterday].categories)],
                  labels: formatStatusLabels({ intl, dayStatus, yesterday }),
                  type: "pie",
                },
              ]}
              layout={{
                height: 300,
                width: 400,
                margin: { t: 25, l: 10, b: 5, r: 5 },
              }}
            />
          )}
        </Grid>
        <Grid item xs={4} className={classes.ChartGrids}>
          <h5 className={classes.ChartHeaders}>
            <FormattedMessage id="status.errors_per_day" />
          </h5>
          <Plot
            data={[
              {
                y: prevDays.map((day) => (dayStatus[day] ? dayStatus[day].nErrors : 0)),
                x: prevDays,
                type: "bar",
              },
            ]}
            layout={{
              height: 300,
              width: 500,
              xaxis: {
                type: "category",
              },
              margin: { t: 25, l: 20, b: 60, r: 5 },
            }}
          />
        </Grid>
        <Grid item xs={4} className={classes.ChartGrids}>
          <h5 className={classes.ChartHeaders}>
            <FormattedMessage id="status.total_number_of_metering_point_without_values" />
          </h5>
          <Plot
            data={[
              {
                y: prevDays.map((day) => (dayStatus[day] ? dayStatus[day].hasNoMeasures?.length : 0)),
                x: prevDays,
                type: "bar",
              },
            ]}
            layout={{
              height: 300,
              width: 500,
              xaxis: {
                type: "category",
              },
              margin: { t: 25, l: 20, b: 60, r: 5 },
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.StatusLists}>
          {dayStatus.errorList.length > 0 ? (
            <>
              <Typography variant="h6" color="textSecondary">
                <FormattedMessage id="status.error_list" />
              </Typography>
              <Paper>
                <MUIDataTable data={dayStatus.errorList} columns={errorColumns} options={tableOptions} />
              </Paper>
            </>
          ) : (
            <FormattedMessage id="status.no_errors_reported" />
          )}
        </Grid>
        <Grid item xs={12} className={classes.StatusLists}>
          {dayStatus.missingMeasuresList.length > 0 ? (
            <>
              <Typography variant="h6" color="textSecondary">
                <FormattedMessage id="status.error_list_missing_measures" />
              </Typography>
              <Paper>
                <MUIDataTable data={dayStatus.missingMeasuresList} columns={missingColumns} options={tableOptions} />
              </Paper>
            </>
          ) : (
            <FormattedMessage id="status.no_missing_meter_readings" />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(styles)(injectIntl(Status));
