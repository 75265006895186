import React from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { createBrowserHistory } from "history";

import PropTypes from "prop-types";
import classNames from "classnames";
import Cookies from "js-cookie";

import { withStyles } from "@material-ui/core";

import Login from "./components/Login";
import ImportStatusView from "./components/ImportStatusView";
import Alarms from "./components/Alarms";
import MeteringPoint from "./components/MeteringPoint";
import DifferenceCalculation from "./components/DifferenceCalculation";
import Configuration from "./components/Configuration";
import MeteringPoints from "./components/MeteringPoints";
import Cooling from "./components/Cooling";

import messages from "./messages";
import AppContext from "./AppContext";
import AppBarDrawer from "./components/AppBarDrawer";

import styles from "./styles";

const languages = {
  en: "English",
  sv: "Svenska",
};

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontSize: 14,
  },
  palette: {
    primary: {
      main: "#00518D",
    },
    secondary: {
      main: "#E74B4C",
    },
  },
  overrides: {
    MuiSlider: {
      trackBefore: { opacity: 0.24 },
      trackAfter: { opacity: 0.24 },
    },
  },
});

const history = createBrowserHistory();

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      language: Cookies.get("k2_language") || "sv",
      appMenuOpen: false,
      config: null,
    };
  }

  updateLanguage = (language) => {
    Cookies.set("k2_language", language, { expires: new Date(Date.now() + 1000000000000), sameSite: "lax" });
    this.setState({ language });
  };

  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <AppContext.Provider value={this.props.appContext}>
          <IntlProvider locale={this.state.language} messages={messages[this.state.language]}>
            {this.props.appContext.keycloak.authenticated ? (
              <Router history={history}>
                <div className={classNames(classes.AppRoot, classes.FlexGrow)}>
                  <AppBarDrawer
                    languages={languages}
                    context={this.props.appContext}
                    updateLanguage={this.updateLanguage}
                  />
                  <main className={classNames(classes.AppContent, classes.FlexGrow)}>
                    <div className={classes.AppToolbar} />
                    <Switch>
                      <Route exact path="/status" component={ImportStatusView} />
                      <Route exact path="/alarms" component={Alarms} />
                      <Route
                        exact
                        path="/meteringpoint/:meteringPointId"
                        render={(props) => (
                          <MeteringPoint
                            {...props}
                            meteringPointId={props.match.params.meteringPointId}
                            context={this.props.appContext}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/meteringpoint"
                        render={() => <MeteringPoint context={this.props.appContext} />}
                      />
                      <Route
                        exact
                        path="/diffcalculation/:meteringPointId"
                        render={(props) => (
                          <DifferenceCalculation
                            context={this.props.appContext}
                            meteringPointId={props.match.params.meteringPointId}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/diffcalculation"
                        render={() => <DifferenceCalculation context={this.props.appContext} />}
                      />
                      <Route exect path="/meteringpoints" component={MeteringPoints} />
                      <Route exect path="/cooling" component={Cooling} />
                      <Route
                        exact
                        path="/configuration"
                        render={() => (
                          <Configuration context={this.props.appContext} updateLanguage={this.updateLanguage} />
                        )}
                      />
                      <Route render={() => <Redirect to="/alarms" />} />
                    </Switch>
                  </main>
                </div>
              </Router>
            ) : (
              <Login />
            )}
          </IntlProvider>
        </AppContext.Provider>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  appContext: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
