import { useContext } from "react";
import AppConxt from "../AppContext";

function useStatus(day) {
  const context = useContext(AppConxt);

  return {
    token: context.keycloak.token,
    meterReadingsAPI: context.config.meterReadingsAPI,
    meteringPointsAPI: context.config.meteringPointsAPI,
  };
}

export default useStatus;
