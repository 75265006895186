import React from 'react';
import { Toolbar, Button, ButtonGroup, CircularProgress, withStyles } from '@material-ui/core';
import styles from "../styles";
import { injectIntl, FormattedMessage } from "react-intl";

const StatusDaysButtonGroup = ({ isLoading, setnDays, nDays, classes }) => {
    return (
      <Toolbar className={classes.Toolbar}>
        <ButtonGroup variant="contained" color="primary" aria-label="text primary button group" style={{ position: 'relative' }}>
        <Button
            disabled={isLoading}
            onClick={() => setnDays(5)}
            className={classes.ButtonLeft + (nDays === 5 ? ' active' : '')}
          >
            <FormattedMessage id="status.reading_5_days" />
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => setnDays(14)}
            className={classes.ButtonLeft + (nDays === 14 ? ' active' : '')}
          >
            <FormattedMessage id="status.reading_14_days" />
          </Button>

          {isLoading && (
            <div className={classes.Loading}>
              <CircularProgress size={24} style={{ marginRight: 8 }} />
              <span className={classes.LoadingSpan}>
              <FormattedMessage id='status.loading'/> <FormattedMessage {...(nDays === 5 ? { id: 'status.reading_5_days' } : { id: 'status.reading_14_days' })} />
              </span>
            </div>
          )}
        </ButtonGroup>
      </Toolbar>
    );
  };
  
  export default withStyles(styles)(injectIntl(StatusDaysButtonGroup));