import { useEffect, useState } from "react";
import useMeterReadings from "./useMeterReadings";
import useMeteringPoints from "./useMeteringPoints";
import moment from "moment";
import { FormattedMessage } from "react-intl";

function useStatus(day) {
  const [nDays, setnDays] = useState(5);
  const [isLoading, setIsLoading] = useState(true);

  const [dayStatus, setDayStatus] = useState({
    errorList: [],
    missingMeasuresList: [],
  });
  const [days, setDays] = useState(getDayArray(nDays));
  const { getReadings } = useMeterReadings();
  const { getPoints } = useMeteringPoints();

  const formattedErrorCodes = {
    validate_simple_error_negative_value: <FormattedMessage id="status.validate_simple_error_negative_value" />,
    validate_simple_error_in_future: <FormattedMessage id="status.validate_simple_error_in_future" />,
    validate_simple_error_false_positive: <FormattedMessage id="status.validate_simple_error_false_positive" />,
    calculate_consuming_values_error: <FormattedMessage id="status.calculate_consuming_values_error" />,
    total_number_of_metering_point_without_values: (
      <FormattedMessage id="status.total_number_of_metering_point_without_values" />
    ),
    validate_advanced_error_no_weather_data: <FormattedMessage id="status.validate_advanced_error_no_weather_data" />,
    validate_advanced_error_no_metering_point_found: (
      <FormattedMessage id="status.validate_advanced_error_no_metering_point_found" />
    ),
    validate_advanced_error_no_weather_station_info: (
      <FormattedMessage id="status.validate_advanced_error_no_weather_station_info" />
    ),
    validate_advanced_error_unknown: <FormattedMessage id="status.validate_advanced_error_unknown" />,
    validate_advanced_error_no_reference_period: (
      <FormattedMessage id="status.validate_advanced_error_no_reference_period" />
    ),
    validate_advanced_error_no_reference_readings: (
      <FormattedMessage id="status.validate_advanced_error_no_reference_readings" />
    ),
    validate_advanced_error_too_few_reference_values: (
      <FormattedMessage id="status.validate_advanced_error_too_few_reference_values" />
    ),
  };

  function getDayArray(n) {
    return [...Array(n).keys()].map((n) => moment(day).subtract(n, "days").format("YYYY-MM-DD"));
  }

  async function calcStatus() {
    setIsLoading(true);
    const timestamp = moment(day).subtract(nDays, "days").startOf("day").toISOString();
    const readings = await getReadings(timestamp);
    const points = await getPoints();

    const status = readings.reduce(
      (memo, reading) => {
        const day = moment(reading._last_updated_utc).format("YYYY-MM-DD");
        if (!memo[day]) {
          memo[day] = {
            categories: {},
            nMeasures: 0,
            nErrors: 0,
            hasMeasures: {},
          };
        }
        const err = reading.status?.find((status) => status.val.indexOf("error") !== -1);
        const current = memo[day];
        current.nMeasures++;

        if (!current.hasMeasures[reading.metering_point_id]) {
          current.hasMeasures[reading.metering_point_id] = 1;
        }

        if (err) {
          const errorCode = err.val;
          if (!current["categories"][err.val]) {
            current["categories"][err.val] = 0;
          }
          current.categories[err.val]++;
          current.nErrors++;
          const errorDesc = formattedErrorCodes[errorCode] || "Unknown error";

          const findAddress = points.find((address) => address.metering_point_id === reading.metering_point_id);
          memo.errorList.push({
            _id: reading.metering_point_id,
            error: err.val,
            timestamp: reading._last_updated_utc,
            desc: errorDesc,
            address: findAddress
              ? {
                  street_address: findAddress.location.address.street_address,
                }
              : { street_address: <FormattedMessage id="status.no_address" /> },
          });
        }
        return memo;
      },
      { errorList: [], missingMeasuresList: [] }
    );

    days.forEach((day) => {
      if (status[day]) {
        const hasMeasures = Object.keys(status[day].hasMeasures);
        status[day].hasNoMeasures = points.filter((point) => hasMeasures.indexOf(point.metering_point_id) === -1);
        status.missingMeasuresList = [
          ...status.missingMeasuresList,
          ...status[day].hasNoMeasures.map((point) => {
            const location = point.location;
            const address = location && location.address;

            return {
              metering_point_id: point.metering_point_id,
              day: day,
              address: {
                street_address: address?.street_address || <FormattedMessage id="status.no_address" />,
              },
            };
          }),
        ];
      }
    });

    setIsLoading(false);
    setDayStatus(status);
  }

  useEffect(() => {
    setDays(getDayArray(nDays));
    calcStatus();
  }, [nDays]);

  const wrap = (str, size = 4, delimiter = "<br>") => {
    const words = str.split(" ");
    return Array.from({ length: Math.ceil(words.length / size) }, (v, i) =>
      words.slice(i * size, i * size + size).join(" ")
    ).join(delimiter);
  };

  const formatStatusLabels = ({ intl, dayStatus, yesterday }) => {
    return [
      intl.formatMessage({ id: "status.no_error" }),
      ...Object.keys(dayStatus[yesterday].categories).map((cat) => {
        return wrap(intl.formatMessage({ id: "status." + cat }));
      }),
    ];
  };

  return {
    isLoading,
    dayStatus,
    prevDays: days,
    setnDays,
    nDays,
    formatStatusLabels,
  };
}

export default useStatus;
