import React, { useState, useEffect, useContext } from "react";
import { useIntl } from "react-intl";

import { Paper, Grid, Tooltip, IconButton } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import AppContext from "../AppContext";
import localforage from "localforage";

import { superSearch } from "@codewell/super-search";

export default function CoolingTable(props) {
  const context = useContext(AppContext);
  const intl = useIntl();
  const columns = [
    {
      id: "metering_point_id",
      align: "left",
      label: intl.formatMessage({ id: "metering_points.metering_point" }),
      options: {
        filter: false,
      },
    },
    {
      id: "street_address",
      align: "left",
      label: intl.formatMessage({ id: "metering_points.address" }),
    },
    {
      id: "energy",
      align: "left",
      label: intl.formatMessage({ id: "energy" }),
    },
    {
      id: "flow",
      align: "left",
      label: intl.formatMessage({ id: "flow" }),
    },
    {
      id: "overflow",
      align: "right",
      label: intl.formatMessage({ id: "cooling.overflow" }),
    },
    {
      id: "delta_t",
      align: "right",
      label: intl.formatMessage({ id: "cooling.delta_t" }),
    },
    {
      id: "momentan_delta_t",
      align: "right",
      label: intl.formatMessage({ id: "cooling.momentan_delta_t" }),
    },
    {
      id: "flow_temperature",
      align: "right",
      label: intl.formatMessage({ id: "cooling.flow_temperature" }),
    },
    {
      id: "return_temperature",
      align: "right",
      label: intl.formatMessage({ id: "cooling.return_temperature" }),
    },
    {
      id: "calculated_at",
      align: "right",
      label: intl.formatMessage({ id: "cooling.calculated_at" }),
    },
  ];

  const options = {
    downloadOptions: {
      filename: "Metering_points.csv",
      separator: ";",
    },
    filterType: "multiselect",
    selectableRows: "none",
    responsive: "scrollMaxHeigh",

    rowsPerPage: 100,
    rowsPerPageOptions: [10, 20, 50, 100],
    viewColumns: true,
    textLabels: intl.messages["MUIDataTables.textLabels"],
    onRowClick: (rowData) => {
      props.history?.push("meteringpoint/" + rowData[0]);
    },
    setTableProps: () => {
      return { size: "small" };
    },
    customToolbar: () => {
      return (
        <Tooltip placement="bottom" title={intl.formatMessage({ id: "metering_points.update" })}>
          <IconButton
            onClick={() => {
              fetchAllMeteringPoints();
            }}
            style={{ marginLeft: "0px" }}
          >
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      );
    },
    customSearch: (searchText, row) => superSearch({ ignore: [" "] })(searchText.toLowerCase(), { ...row }).fullMatch,
  };

  useEffect(() => {
    localforage
      .getItem("meteringPoints")
      .then((value) => {
        if (value === null) {
          fetchAllMeteringPoints();
        } else {
          updateTable(value);
        }
      })
      .catch(() => {
        console.log("Error reading from localforage");
        fetchAllMeteringPoints();
      });
  }, []);

  function updateTable(listOfMeteringPoints) {
    let object_period_select = "_" + props.period;
    let list = [];
    listOfMeteringPoints.forEach((meteringPoint) => {
      try {
        var filteredMeteringsPoint = {
          metering_point_id: meteringPoint.metering_point_id,
          street_address: meteringPoint.location
            ? meteringPoint.location.address
              ? meteringPoint.location.address.street_address
              : "-"
            : "-",
          energy: meteringPoint.period_values
            ? meteringPoint.period_values.energy[object_period_select].toFixed(3)
            : "-",
          flow: meteringPoint.period_values ? meteringPoint.period_values.flow[object_period_select].toFixed(3) : "-",
          overflow: meteringPoint.period_values
            ? meteringPoint.period_values.overflow[object_period_select]?.toFixed(3)
            : "-",
          delta_t: meteringPoint.period_values
            ? meteringPoint.period_values.delta_t[object_period_select].toFixed(3)
            : "-",
          momentan_delta_t: meteringPoint.period_values
            ? meteringPoint.period_values.flow_temperature[object_period_select].toFixed(3) -
              meteringPoint.period_values.return_temperature[object_period_select].toFixed(3)
            : "-",
          flow_temperature: meteringPoint.period_values
            ? meteringPoint.period_values.flow_temperature[object_period_select].toFixed(3)
            : "-",
          return_temperature: meteringPoint.period_values
            ? meteringPoint.period_values.return_temperature[object_period_select].toFixed(3)
            : "-",
          calculated_at: meteringPoint.period_values ? meteringPoint.period_values.calculated_at.slice(0, 10) : "-",
        };

        list.push([
          filteredMeteringsPoint.metering_point_id,
          filteredMeteringsPoint.street_address,
          filteredMeteringsPoint.energy,
          filteredMeteringsPoint.flow,
          filteredMeteringsPoint.overflow,
          filteredMeteringsPoint.delta_t,
          filteredMeteringsPoint.momentan_delta_t,
          filteredMeteringsPoint.flow_temperature,
          filteredMeteringsPoint.return_temperature,
          filteredMeteringsPoint.calculated_at,
        ]);
      } catch (err) {
        console.log(err);
      }
    });
    list.sort((a, b) => {
      for (let columnIndex = 0; columnIndex < a.length; columnIndex++) {
          const valueA = String(a[columnIndex]);
          const valueB = String(b[columnIndex]);
          
          const stringComparison = valueA.localeCompare(valueB);
          if (stringComparison !== 0) {
              return stringComparison;
          }
      }
      return 0;
    });

    return list;
  }

  const meteringPoints = updateTable(props.meteringPoints);

  function fetchMeteringPoints(token) {
    var headers = { Authorization: "Bearer " + token };
    var params = { limit: 9999999999 };
    return axios.get(context.config.meteringPointsAPI + "/", { headers, params });
  }

  async function fetchAllMeteringPoints() {
    context.keycloak
      .updateToken(5)
      .success(function () {
        axios
          .all([fetchMeteringPoints(context.keycloak.token)])
          .then(
            axios.spread(function (meteringPointResponse) {
              localforage.setItem("meteringPoints", meteringPointResponse.data);
              updateTable(meteringPointResponse.data);
            })
          )
          .catch((error) => {
            console.log(error);
          });
      })
      .error(function () {
        context.keycloak.login();
      });
  }

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper>
            <MUIDataTable data={meteringPoints} columns={columns} options={options} />
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
